import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-may-bonus',
  templateUrl: './may-bonus.component.html',
})
export class MayBonusComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
