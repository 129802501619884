<section *ngIf="data.poolSize < 50" class="my-4">
  <h1 class="text-3xl font-light">{{ data.user.gamertag }}</h1>
  <span class="my-4 inline-block text-sm text-gray-400"
    >Pool Size:
    <span class="text-2xl text-red-500">{{data.poolSize}}</span></span
  >
  <p>Not enough eligible games!</p>
</section>
<section *ngIf="data.poolSize >= 50" class="my-4">
  <div class="my-4">
    <dt class="mb-2 text-4xl font-extrabold">{{ data.user.gamertag }}</dt>
    <div
      class="rounded-lg bg-gray-800 p-4 md:p-8"
      id="playerStats"
      role="tabpanel"
      aria-labelledby="playerStats-tab"
    >
      <dl class="mx-auto grid max-w-screen-xl grid-cols-3 gap-8 p-8 text-white">
        <div class="flex flex-col items-center justify-center">
          <dt class="mb-2 text-center text-xl font-extrabold">
            {{ data.result.achievementCount }} / {{
            data.result.game.achievementCount }}
          </dt>
          <dd class="text-gray-400">Achievement Progress</dd>
        </div>
        <div class="flex flex-col items-center justify-center">
          <dt class="mb-2 text-center text-xl font-extrabold">
            {{ data.result.startedDate ? (data.result.startedDate | date:
            'MM/dd/yy') : 'Not Started' }}
          </dt>
          <dd class="text-gray-400">Started</dd>
        </div>
        <div class="flex flex-col items-center justify-center">
          <dt class="mb-2 text-center text-xl font-extrabold">
            {{ data.result.lastUnlock ? (data.result.lastUnlock | date:
            'MM/dd/yy') : 'Not Started' }}
          </dt>
          <dd class="text-gray-400">Last Unlock</dd>
        </div>
      </dl>
    </div>
  </div>
  <div>
    <dt class="mb-2 text-4xl font-extrabold">{{ data.result.game.title }}</dt>
    <div
      class="rounded-lg bg-gray-800 p-4 md:p-8"
      id="stats"
      role="tabpanel"
      aria-labelledby="stats-tab"
    >
      <dl class="mx-auto grid max-w-screen-xl grid-cols-3 gap-8 p-8 text-white">
        <div class="flex flex-col items-center justify-center">
          <dt class="mb-2 text-center text-xl font-extrabold">
            {{ data.result.game.publisher }}
          </dt>
          <dd class="text-gray-400">Publisher</dd>
        </div>
        <div class="flex flex-col items-center justify-center">
          <dt class="mb-2 text-xl font-extrabold" text-center>
            {{ data.result.game.fullCompletionEstimate }} hours
          </dt>
          <dd class="text-gray-400">Estimate</dd>
        </div>
        <div class="flex flex-col items-center justify-center">
          <dt class="mb-2 text-center text-xl font-extrabold">
            {{ data.result.game.releaseDate | date: 'MM/dd/yy' }}
          </dt>
          <dd class="text-gray-400">Release Date</dd>
        </div>
        <div class="flex flex-col items-center justify-center">
          <dt class="mb-2 text-center text-xl font-extrabold">
            {{ data.result.game.developer }}
          </dt>
          <dd class="text-gray-400">Developer</dd>
        </div>
        <div class="flex flex-col items-center justify-center">
          <dt class="mb-2 text-center text-xl font-extrabold">
            {{ data.result.game.siteRatio }}
          </dt>
          <dd class="text-gray-400">Ratio</dd>
        </div>
        <div class="flex flex-col items-center justify-center">
          <dt
            class="mb-2 flex items-center gap-2 text-center text-xl font-extrabold"
          >
            <span>~{{ data.bcmValue }} points</span>
            <img
              class="inline h-[24px]"
              src="../../../assets/360up.png"
              *ngIf="data.result.platform.value === 1"
            />
          </dt>
          <dd class="text-gray-400">BCM Value</dd>
        </div>
      </dl>
    </div>
  </div>
</section>
