<button mat-raised-button color="primary" (click)="fullSync()">
  Full Sync
</button>
<br />
<button mat-raised-button color="primary" (click)="rgscSync()">
  Rgsc Sync
</button>
<br />
<button mat-raised-button color="primary" (click)="shallowSync()">
  Shallow Sync
</button>
<br />
<div>
  <span>Number of Players to Sync: {{ playersToSync }}</span>
  <br />
  <span>Number of estimated TA Hits: {{ totalTaHits | number }}</span>
  <br />
  <span
    >Total Estimated Time:
    {{ totalEstimatedTime! / 60 | number }} minute(s)</span
  >
  <br />
  <br />
  <span
    >Players Scanned: {{ scannedPlayerCount - 1 }} out of
    {{ playersToSync }}</span
  >
  <br />
  <mat-progress-bar
    mode="determinate"
    value="{{ progressBarStatus }}"
  ></mat-progress-bar>
  <span>Status: {{ syncStatus }}</span>
</div>
<br />
<button mat-raised-button color="primary" (click)="updateGameInfo()">
  Update Game Info
</button>
<br />
<button mat-raised-button color="primary" disabled (click)="testGwgParse()">
  Parse GwG
</button>
<br />
<!-- Add time elapsed for the user -->
