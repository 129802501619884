<ng-container *ngIf="this.isPlayerLoading">
  <main
    class="absolute left-0 top-[65px] flex h-[calc(100%-65px)] w-full items-center justify-center bg-black bg-opacity-50"
  >
    <img src="../../../assets/infinite-spinner.svg" class="h-16" />
  </main>
</ng-container>
<div class="flex flex-col justify-center" *ngIf="!isPlayerLoading">
  <section class="flex flex-col items-center justify-center align-middle">
    <div
      class="m-4 max-w-md rounded-lg border border-gray-700 bg-gray-900 px-6 shadow"
    >
      <div class="relative flex items-center gap-8 p-4">
        <img
          class="h-20 w-20 rounded-full shadow-lg"
          src="{{ bcmPlayerSummary.player.user.avatar }}"
          (error)="handleImageLoad($event)"
        />
        <div class="self-start text-sm text-gray-400">
          <ul>
            <li>
              <h5 class="text-lg font-medium text-white">
                {{ bcmPlayerSummary.player.user.gamertag }}'s Completions
              </h5>
            </li>
            <li></li>
          </ul>
          <button
            [routerLink]="['/player/', playerName]"
            class="my-2 me-2 rounded-lg border border-green-700 px-3 py-1.5 text-center text-sm font-medium text-green-700 hover:bg-green-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-green-300 dark:border-green-500 dark:text-green-500 dark:hover:bg-green-600 dark:hover:text-white dark:focus:ring-green-800"
          >
            Return to Profile
          </button>
        </div>
      </div>
    </div>
  </section>
  <div
    class="w-full max-w-5xl self-center overflow-x-auto shadow-md sm:rounded-lg"
  >
    <table
      class="w-full bg-gray-700 text-left text-sm text-gray-400 rtl:text-right"
    >
      <thead>
        <tr class="flex bg-gray-700 text-xs uppercase text-gray-400">
          <th
            class="w-24 cursor-pointer px-6 py-3 hover:text-green-500 sm:p-4"
            (click)="sortColumn('date')"
          >
            Date
          </th>
          <th
            class="min-w-[16rem] max-w-md flex-1 cursor-pointer px-6 py-3 hover:text-green-500 sm:p-4"
            (click)="sortColumn('title')"
          >
            Title
          </th>
          <th class="w-24 px-6 py-3"></th>
          <th
            class="w-24 cursor-pointer px-6 py-3 hover:text-green-500 sm:p-4"
            (click)="sortColumn('points')"
          >
            Base Pts
          </th>
          <th
            class="w-28 cursor-pointer px-6 py-3 text-violet-400 hover:text-green-500 sm:p-4"
            (click)="sortColumn('points')"
          >
            Bonus Pts
          </th>
          <th
            class="w-24 cursor-pointer px-6 py-3 hover:text-green-500 sm:p-4"
            (click)="sortColumn('ratio')"
          >
            Ratio
          </th>
          <th
            class="w-24 cursor-pointer px-6 py-3 hover:text-green-500 sm:p-4"
            (click)="sortColumn('estimate')"
          >
            Estimate
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="flex border-b border-gray-700 bg-gray-800"
          *ngFor="let playersGame of bcmPlayerSummary.games"
        >
          <td class="w-24 whitespace-nowrap px-6 py-4">
            {{ playersGame.game.completionDate | date : "MMM d" : "UTC" }}
          </td>
          <td
            class="min-w-[16rem] max-w-md flex-1 overflow-hidden text-ellipsis whitespace-nowrap px-6 py-4 font-medium text-white"
          >
            {{ playersGame.game.game.title }}
          </td>
          <td
            class="flex w-24 items-center gap-1 px-6 py-4 font-bold text-green-400"
          >
            <span *ngIf="playersGame.bonus === 1">Jan</span>
            <span *ngIf="playersGame.bonus === 2">Feb</span>
            <span *ngIf="playersGame.bonus === 3">Mar</span>
            <span *ngIf="playersGame.bonus === 4">Apr</span>
            <span *ngIf="playersGame.bonus === 5">May</span>
            <span *ngIf="playersGame.bonus === 6">Jun</span>
            <span *ngIf="playersGame.bonus === 7">Jul</span>
            <span *ngIf="playersGame.bonus === 8">Aug</span>
            <span *ngIf="playersGame.bonus === 9">Sep</span>
            <span *ngIf="playersGame.bonus === 10">Oct</span>
            <span *ngIf="playersGame.bonus === 11">Nov</span>
            <img
              *ngIf="playersGame.bonus"
              class="h-4"
              src="../../../../assets/up.png"
            />
          </td>
          <td class="w-24 px-6 py-4">
            {{
              playersGame.game.game.fullCompletionEstimate !== null
                ? playersGame.points
                : "--"
            }}
          </td>
          <td class="w-28 px-6 py-4 text-violet-400">
            {{ playersGame.game.bcmPoints | number : "1.0-0" }}
          </td>
          <td class="w-24 px-6 py-4">
            {{ playersGame.game.game.siteRatio | number : "1.2-2" }}
          </td>
          <td class="w-24 px-6 py-4">
            {{ playersGame.game.game.fullCompletionEstimate || "--" }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
