<h4 class="my-4 text-xl font-semibold">Unregister?</h4>
<p>
  To unregister for 2024, click the button below. Your registration date will be
  removed and if you decide to register again, all of your completions previous
  to your new registration date will no longer be counted.<br />
  <button
    type="button"
    class="me-2 my-4 mb-2 rounded-lg bg-red-600 px-5 py-2.5 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-red-800"
  >
    Unregister
  </button>
</p>
