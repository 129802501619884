import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mar-bonus',
  templateUrl: './mar-bonus.component.html',
})
export class MarBonusComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
