<section class="flex place-content-center">
  <div
    class="min-w-full max-w-7xl md:min-w-96"
    id="accordion-nested-parent"
    data-accordion="collapse"
  >
    <h2 id="accordion-collapse-heading-1">
      <button
        type="button"
        class="flex w-full items-center justify-between gap-3 rounded-t-xl border border-b-0 border-gray-700 !bg-gray-900 p-5 font-medium !text-gray-400 hover:!bg-gray-800 focus:ring-4 focus:ring-gray-800 rtl:text-right"
        data-accordion-target="#accordion-collapse-body-1"
        aria-expanded="true"
        aria-controls="accordion-collapse-body-1"
        (click)="toggleCategory('communityStar')"
      >
        <span>eohjay's Community Star</span>
        <svg
          data-accordion-icon
          class="h-3 w-3 shrink-0 rotate-180"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5 5 1 1 5"
          />
        </svg>
      </button>
    </h2>
    <div
      id="accordion-collapse-body-1"
      [attr.class]="communityStarVisible ? 'visible' : 'hidden'"
    >
      <div class="border border-b-0 border-gray-700 p-5">
        <p class="mb-5 text-xs text-gray-500">
          Earn an extra 5,000 points for completing a game in each of the
          challenges below. Qualifying games must be 1.5 ratio or above with and
          be at least 6 hours long, OR 20 or more hours long (regardless of
          ratio)
        </p>
        <!-- Nested accordion -->
        <div id="accordion-nested-collapse" data-accordion="collapse">
          <ng-container *ngFor="let item of commStarData; let i = index">
            <h2 [id]="'accordion-nested-collapse-heading-' + i">
              <button
                type="button"
                [ngClass]="{
                  'flex w-full items-center justify-between gap-3 rounded-t-xl border border-b-0 border-gray-700 !bg-gray-900 p-5 font-medium !text-gray-400 hover:!bg-gray-800 focus:ring-4 focus:ring-gray-800 rtl:text-right': true,
                  '!bg-gradient-to-br !from-gray-900 !via-gray-900 !to-green-800':
                    item.playerYearlyChallenge?.approved === true
                }"
                aria-expanded="false"
                (click)="toggleStarSection(i)"
              >
                <span class="flex-1 text-left">{{
                  item.yearlyChallenge.title
                }}</span>
                <span class="flex items-center gap-2"
                  >{{ item.playerYearlyChallenge?.game?.title }}
                  <img
                    *ngIf="item.playerYearlyChallenge?.approved === false"
                    src="../../../../assets/warning-svgrepo-com.svg"
                    class="mt-1 h-6 w-6"
                    title="This submission is awaiting approval"
                  />
                  <img
                    *ngIf="item.playerYearlyChallenge?.approved === true"
                    src="../../../../assets/checkmark-seal-svgrepo-com.svg"
                    class="mt-1 h-6 w-6"
                    title="This submission has been approved"
                  />
                </span>
                <svg
                  data-accordion-icon
                  class="ml-8 h-3 w-3 shrink-0 rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </button>
            </h2>
            <div
              [id]="'accordion-nested-collapse-body-' + i"
              [attr.class]="item.isVisible ? 'visible' : 'hidden'"
            >
              <div
                class="flex flex-col border border-b-0 border-gray-700 bg-slate-800 p-5"
              >
                <p class="text-sm text-gray-400">
                  {{ item.yearlyChallenge.description }}
                  <select
                    [id]="'communityStarOption' + i"
                    class="my-4 block w-full rounded-lg border border-gray-600 bg-gray-700 p-2.5 text-sm text-white placeholder-gray-400 focus:border-green-500 focus:ring-green-500"
                    [(ngModel)]="item.option"
                    [disabled]="playerName !== currentUser"
                    (blur)="saveAutomatedSelect(item)"
                  >
                    <option></option>
                    <option
                      *ngFor="let option of optionsArray"
                      [value]="option.value"
                      [disabled]="option.disabled"
                    >
                      {{ option.title }}
                    </option>
                    <option value="writein">--&nbsp;Write In&nbsp;--</option>
                  </select>
                </p>
                <button
                  class="disabled:grayscale-1 inline-flex max-w-fit items-center self-end rounded-lg bg-green-600 px-3 py-2 text-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-800 disabled:pointer-events-none disabled:cursor-default disabled:grayscale"
                  disabled
                >
                  Community Submissions
                </button>
                <div
                  *ngIf="
                    item.option === 'writein' && playerName === currentUser
                  "
                >
                  <div class="mb-5">
                    <label for="base-input" class="text-sm text-gray-400"
                      >Provide a link to the game on TA</label
                    >
                    <input
                      type="text"
                      id="base-input"
                      class="block w-full rounded-lg border border-gray-600 bg-gray-700 p-2.5 text-sm text-white placeholder-gray-400 focus:border-green-500 focus:ring-green-500"
                      [(ngModel)]="item?.playerYearlyChallenge.writeIn"
                      (blur)="saveTaLink(item)"
                      [disabled]="playerName !== currentUser"
                    />
                  </div>
                  <label for="message" class="text-sm text-gray-400"
                    >Any reasoning you'd like to add? (Optional)</label
                  >
                  <textarea
                    id="message"
                    rows="4"
                    class="block w-full rounded-lg border border-gray-600 bg-gray-700 p-2.5 text-sm text-white placeholder-gray-400 focus:border-green-500 focus:ring-green-500"
                    placeholder="Reasoning..."
                    [(ngModel)]="item?.playerYearlyChallenge.reasoning"
                    (blur)="saveReasoning(item)"
                    [disabled]="playerName !== currentUser"
                  ></textarea>
                </div>
              </div>
            </div>
          </ng-container>
          <div *ngIf="currentUser === playerName">
            <button
              class="group relative my-4 mb-2 inline-flex items-center justify-center overflow-hidden rounded-lg bg-gradient-to-br from-purple-600 to-green-500 p-0.5 text-sm font-medium text-white hover:text-white focus:outline-none focus:ring-4 focus:ring-green-800 disabled:pointer-events-none disabled:cursor-default disabled:grayscale group-hover:from-purple-600 group-hover:to-green-500"
              disabled
            >
              <span
                class="relative rounded-md bg-gray-900 px-5 py-2.5 transition-all duration-75 ease-in group-hover:bg-opacity-0"
              >
                Submit Community Star
              </span>
            </button>
          </div>
        </div>
        <!-- End: Nested accordion -->
      </div>
    </div>
    <h2 id="accordion-collapse-heading-1">
      <button
        type="button"
        class="flex w-full items-center justify-between gap-3 rounded-t-xl border border-b-0 border-gray-700 !bg-gray-900 p-5 font-medium !text-gray-400 hover:!bg-gray-800 focus:ring-4 focus:ring-gray-800 rtl:text-right"
        data-accordion-target="#accordion-collapse-body-1"
        aria-expanded="true"
        aria-controls="accordion-collapse-body-1"
        (click)="toggleCategory('theTavis')"
      >
        <span>Echo's The T. A. V. I. S.</span>
        <svg
          data-accordion-icon
          class="h-3 w-3 shrink-0 rotate-180"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5 5 1 1 5"
          />
        </svg>
      </button>
    </h2>
    <div
      id="accordion-collapse-body-2"
      [attr.class]="theTavisVisible ? 'visible' : 'hidden'"
    >
      <div class="border border-b-0 border-gray-700 p-5">
        <p class="mb-5 text-xs text-gray-500">
          Earn an extra 5,000 points for completing a game in each of the
          challenges below. Qualifying games must be 1.5 ratio or above with and
          be at least 6 hours long, OR 20 or more hours long (regardless of
          ratio)
        </p>
        <!-- Nested accordion -->
        <div id="accordion-nested-collapse" data-accordion="collapse">
          <ng-container *ngFor="let item of theTavisData; let i = index">
            <h2 [id]="'accordion-nested-collapse-heading-' + i">
              <button
                type="button"
                [ngClass]="{
                  'flex w-full items-center justify-between gap-3 rounded-t-xl border border-b-0 border-gray-700 !bg-gray-900 p-5 font-medium !text-gray-400 hover:!bg-gray-800 focus:ring-4 focus:ring-gray-800 rtl:text-right': true,
                  '!bg-gradient-to-br !from-gray-900 !via-gray-900 !to-green-800':
                    item.playerYearlyChallenge?.approved === true
                }"
                aria-expanded="false"
                (click)="toggleTavisSection(i)"
              >
                <span class="flex-1 text-left">{{
                  item.yearlyChallenge.title
                }}</span>
                <span class="flex items-center gap-2"
                  >{{ item.playerYearlyChallenge?.game?.title }}
                  <img
                    *ngIf="item.playerYearlyChallenge?.approved === false"
                    src="../../../../assets/warning-svgrepo-com.svg"
                    class="mt-1 h-6 w-6"
                    title="This submission is awaiting approval"
                  />
                  <img
                    *ngIf="item.playerYearlyChallenge?.approved === true"
                    src="../../../../assets/checkmark-seal-svgrepo-com.svg"
                    class="mt-1 h-6 w-6"
                    title="This submission has been approved"
                  />
                </span>
                <svg
                  data-accordion-icon
                  class="ml-8 h-3 w-3 shrink-0 rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </button>
            </h2>
            <div
              [id]="'accordion-nested-collapse-body-' + i"
              [attr.class]="item.isVisible ? 'visible' : 'hidden'"
            >
              <div
                class="flex flex-col border border-b-0 border-gray-700 bg-slate-800 p-5"
              >
                <p class="text-sm text-gray-400">
                  {{ item.yearlyChallenge.description }}
                  <select
                    [id]="'communityStarOption' + i"
                    class="my-4 block w-full rounded-lg border border-gray-600 bg-gray-700 p-2.5 text-sm text-white placeholder-gray-400 focus:border-green-500 focus:ring-green-500"
                    [(ngModel)]="item.option"
                    [disabled]="playerName !== currentUser"
                    (blur)="saveAutomatedSelect(item)"
                  >
                    <option></option>
                    <option
                      *ngFor="let option of optionsArray"
                      [value]="option.value"
                      [disabled]="option.disabled"
                    >
                      {{ option.title }}
                    </option>
                    <option value="writein">--&nbsp;Write In&nbsp;--</option>
                  </select>
                </p>
                <button
                  class="disabled:grayscale-1 inline-flex max-w-fit items-center self-end rounded-lg bg-green-600 px-3 py-2 text-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-800 disabled:pointer-events-none disabled:cursor-default disabled:grayscale"
                  disabled
                >
                  Community Submissions
                </button>
                <div
                  *ngIf="
                    item.option === 'writein' && playerName === currentUser
                  "
                >
                  <div class="mb-5">
                    <label for="base-input" class="text-sm text-gray-400"
                      >Provide a link to the game on TA</label
                    >
                    <input
                      type="text"
                      id="base-input"
                      class="block w-full rounded-lg border border-gray-600 bg-gray-700 p-2.5 text-sm text-white placeholder-gray-400 focus:border-green-500 focus:ring-green-500"
                      [(ngModel)]="item?.playerYearlyChallenge.writeIn"
                      (blur)="saveTaLink(item)"
                      [disabled]="playerName !== currentUser"
                    />
                  </div>
                  <label for="message" class="text-sm text-gray-400"
                    >Any reasoning you'd like to add? (Optional)</label
                  >
                  <textarea
                    id="message"
                    rows="4"
                    class="block w-full rounded-lg border border-gray-600 bg-gray-700 p-2.5 text-sm text-white placeholder-gray-400 focus:border-green-500 focus:ring-green-500"
                    placeholder="Reasoning..."
                    [(ngModel)]="item?.playerYearlyChallenge.reasoning"
                    (blur)="saveReasoning(item)"
                    [disabled]="playerName !== currentUser"
                  ></textarea>
                </div>
              </div>
            </div>
          </ng-container>
          <div *ngIf="currentUser === playerName">
            <button
              class="group relative my-4 mb-2 inline-flex items-center justify-center overflow-hidden rounded-lg bg-gradient-to-br from-purple-600 to-green-500 p-0.5 text-sm font-medium text-white hover:text-white focus:outline-none focus:ring-4 focus:ring-green-800 disabled:pointer-events-none disabled:cursor-default disabled:grayscale group-hover:from-purple-600 group-hover:to-green-500"
              disabled
            >
              <span
                class="relative rounded-md bg-gray-900 px-5 py-2.5 transition-all duration-75 ease-in group-hover:bg-opacity-0"
              >
                Submit The T.A.V.I.S.
              </span>
            </button>
          </div>
        </div>
        <!-- End: Nested accordion -->
      </div>
    </div>
    <h2 id="accordion-collapse-heading-1">
      <button
        type="button"
        class="flex w-full items-center justify-between gap-3 rounded-t-xl border border-b-0 border-gray-700 !bg-gray-900 p-5 font-medium !text-gray-400 hover:!bg-gray-800 focus:ring-4 focus:ring-gray-800 rtl:text-right"
        data-accordion-target="#accordion-collapse-body-1"
        aria-expanded="true"
        aria-controls="accordion-collapse-body-1"
        (click)="toggleCategory('retirementParty')"
      >
        <span>Iron's Retirement Party</span>
        <svg
          data-accordion-icon
          class="h-3 w-3 shrink-0 rotate-180"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5 5 1 1 5"
          />
        </svg>
      </button>
    </h2>
    <div
      id="accordion-collapse-body-3"
      [attr.class]="retirementParty ? 'visible' : 'hidden'"
    >
      <div class="border border-b-0 border-gray-700 p-5">
        <p class="mb-5 text-xs text-gray-500">
          Earn an extra 2,500 points for completing a game in each of the
          challenges below. Qualifying games must be 1.5 ratio or above with and
          be at least 6 hours long, OR 20 or more hours long (regardless of
          ratio)
        </p>
        <!-- Nested accordion -->
        <div id="accordion-nested-collapse" data-accordion="collapse">
          <ng-container *ngFor="let item of retirementData; let i = index">
            <h2 [id]="'accordion-nested-collapse-heading-' + i">
              <button
                type="button"
                [ngClass]="{
                  'flex w-full items-center justify-between gap-3 rounded-t-xl border border-b-0 border-gray-700 !bg-gray-900 p-5 font-medium !text-gray-400 hover:!bg-gray-800 focus:ring-4 focus:ring-gray-800 rtl:text-right': true,
                  '!bg-gradient-to-br !from-gray-900 !via-gray-900 !to-green-800':
                    item.playerYearlyChallenge?.approved === true
                }"
                aria-expanded="false"
                (click)="togglePartySection(i)"
              >
                <span class="flex-1 text-left">{{
                  item.yearlyChallenge.title
                }}</span>
                <span class="flex items-center gap-2"
                  >{{ item.playerYearlyChallenge?.game?.title }}
                  <img
                    *ngIf="item.playerYearlyChallenge?.approved === false"
                    src="../../../../assets/warning-svgrepo-com.svg"
                    class="mt-1 h-6 w-6"
                    title="This submission is awaiting approval"
                  />
                  <img
                    *ngIf="item.playerYearlyChallenge?.approved === true"
                    src="../../../../assets/checkmark-seal-svgrepo-com.svg"
                    class="mt-1 h-6 w-6"
                    title="This submission has been approved"
                  />
                </span>
                <svg
                  data-accordion-icon
                  class="ml-8 h-3 w-3 shrink-0 rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </button>
            </h2>
            <div
              [id]="'accordion-nested-collapse-body-' + i"
              [attr.class]="item.isVisible ? 'visible' : 'hidden'"
            >
              <div
                class="flex flex-col border border-b-0 border-gray-700 bg-slate-800 p-5"
              >
                <p class="text-sm text-gray-400">
                  {{ item.yearlyChallenge.description }}
                  <select
                    [id]="'communityStarOption' + i"
                    class="my-4 block w-full rounded-lg border border-gray-600 bg-gray-700 p-2.5 text-sm text-white placeholder-gray-400 focus:border-green-500 focus:ring-green-500"
                    [(ngModel)]="item.option"
                    [disabled]="playerName !== currentUser"
                    (blur)="saveAutomatedSelect(item)"
                  >
                    <option></option>
                    <option
                      *ngFor="let option of optionsArray"
                      [value]="option.value"
                      [disabled]="option.disabled"
                    >
                      {{ option.title }}
                    </option>
                    <option value="writein">--&nbsp;Write In&nbsp;--</option>
                  </select>
                </p>
                <button
                  class="disabled:grayscale-1 inline-flex max-w-fit items-center self-end rounded-lg bg-green-600 px-3 py-2 text-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-800 disabled:pointer-events-none disabled:cursor-default disabled:grayscale"
                  disabled
                >
                  Community Submissions
                </button>
                <div
                  *ngIf="
                    item.option === 'writein' && playerName === currentUser
                  "
                >
                  <div class="mb-5">
                    <label for="base-input" class="text-sm text-gray-400"
                      >Provide a link to the game on TA</label
                    >
                    <input
                      type="text"
                      id="base-input"
                      class="block w-full rounded-lg border border-gray-600 bg-gray-700 p-2.5 text-sm text-white placeholder-gray-400 focus:border-green-500 focus:ring-green-500"
                      [(ngModel)]="item?.playerYearlyChallenge.writeIn"
                      (blur)="saveTaLink(item)"
                      [disabled]="playerName !== currentUser"
                    />
                  </div>
                  <label for="message" class="text-sm text-gray-400"
                    >Any reasoning you'd like to add? (Optional)</label
                  >
                  <textarea
                    id="message"
                    rows="4"
                    class="block w-full rounded-lg border border-gray-600 bg-gray-700 p-2.5 text-sm text-white placeholder-gray-400 focus:border-green-500 focus:ring-green-500"
                    placeholder="Reasoning..."
                    [(ngModel)]="item?.playerYearlyChallenge.reasoning"
                    (blur)="saveReasoning(item)"
                    [disabled]="playerName !== currentUser"
                  ></textarea>
                </div>
              </div>
            </div>
          </ng-container>
          <div *ngIf="currentUser === playerName">
            <button
              class="group relative my-4 mb-2 inline-flex items-center justify-center overflow-hidden rounded-lg bg-gradient-to-br from-purple-600 to-green-500 p-0.5 text-sm font-medium text-white hover:text-white focus:outline-none focus:ring-4 focus:ring-green-800 disabled:pointer-events-none disabled:cursor-default disabled:grayscale group-hover:from-purple-600 group-hover:to-green-500"
              disabled
            >
              <span
                class="relative rounded-md bg-gray-900 px-5 py-2.5 transition-all duration-75 ease-in group-hover:bg-opacity-0"
              >
                Submit Retirement Party
              </span>
            </button>
          </div>
        </div>
        <!-- End: Nested accordion -->
      </div>
    </div>
  </div>
</section>
