<section
  class="flex flex-col place-items-center justify-center gap-20 md:flex-row"
>
  <div class="flex max-w-md flex-col self-start">
    <section>
      <h3 class="text-xl font-semibold">
        Welcome to Better Completions Matter e(X)perimental Edition!
      </h3>
      <p class="text-sm text-gray-400">
        This year we are moving to a web based system instead of the
        spreadsheets of the past for a multitude of reasons. There's still
        plenty of work to do, so keep checking back for updates as we'll likely
        release a handful of features every month!
        <br /><br />
        Sign in with the profile icon on the top right and follow the
        instructions to be a part of the crew! Welcome aboard!
      </p>
    </section>
    <section class="self-center">
      <iframe
        src="https://discord.com/widget?id=1075212224467046430&theme=dark"
        width="350"
        height="300"
        allowtransparency="true"
        frameborder="0"
        sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
        class="mt-6 self-center"
      ></iframe>
    </section>
  </div>
  <div class="flex max-w-sm self-center md:self-start">
    <section>
      <ol class="relative border-s border-gray-700">
        <li class="mb-10 ms-4">
          <time class="mb-1 text-sm font-normal leading-none text-gray-500"
            >November 2024</time
          >
          <h3 class="text-lg font-semibold text-white">
            So Long And Thanks For All The Fish
          </h3>
          <p class="mb-4 text-base font-normal text-gray-400">
            Complete games from previous podium winners to celebrate the end of
            BCM
          </p>
          <a
            routerLink="/oct-bonus"
            class="inline-flex items-center rounded-lg border border-gray-600 bg-gray-800 px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-700"
            >Read the Details
            <svg
              class="ml-2 ms-2 h-3 w-3 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              /></svg
          ></a>
          <a
            routerLink="/nov-leaderboard"
            class="ml-2 inline-flex items-center rounded-lg border border-green-600 bg-green-800 px-4 py-2 text-sm font-medium text-green-400 hover:bg-green-700 hover:text-white focus:z-10 focus:text-green-700 focus:outline-none focus:ring-4 focus:ring-gray-700 disabled:cursor-default disabled:bg-gray-500"
            disabled
            >Leaderboard
          </a>
        </li>
        <li class="mb-10 ms-4">
          <time class="mb-1 text-sm font-normal leading-none text-gray-500"
            >October 2024</time
          >
          <h3 class="text-lg font-semibold text-white">
            Let's Build a Skeleton!
          </h3>
          <p class="mb-4 text-base font-normal text-gray-400">
            Each game contributes a bone to the skeleton, but the bigger the
            game, the better the bone!
          </p>
          <a
            routerLink="/oct-bonus"
            class="inline-flex items-center rounded-lg border border-gray-600 bg-gray-800 px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-700"
            >Read the Details
            <svg
              class="ml-2 ms-2 h-3 w-3 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              /></svg
          ></a>
          <a
            routerLink="/oct-leaderboard"
            class="ml-2 inline-flex items-center rounded-lg border border-green-600 bg-green-800 px-4 py-2 text-sm font-medium text-green-400 hover:bg-green-700 hover:text-white focus:z-10 focus:text-green-700 focus:outline-none focus:ring-4 focus:ring-gray-700 disabled:cursor-default disabled:bg-gray-500"
            disabled
            >Leaderboard
          </a>
        </li>
        <li class="mb-10 ms-4">
          <time class="mb-1 text-sm font-normal leading-none text-gray-500"
            >September 2024</time
          >
          <h3 class="text-lg font-semibold text-white">Streaking</h3>
          <p class="mb-4 text-base font-normal text-gray-400">
            Chain together games to get the longest streak!
          </p>
          <a
            routerLink="/sep-bonus"
            class="inline-flex items-center rounded-lg border border-gray-600 bg-gray-800 px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-700"
            >Read the Details
            <svg
              class="ml-2 ms-2 h-3 w-3 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              /></svg
          ></a>
          <a
            routerLink="/sep-leaderboard"
            class="ml-2 inline-flex items-center rounded-lg border border-green-600 bg-green-800 px-4 py-2 text-sm font-medium text-green-400 hover:bg-green-700 hover:text-white focus:z-10 focus:text-green-700 focus:outline-none focus:ring-4 focus:ring-gray-700 disabled:cursor-default disabled:bg-gray-500"
            disabled
            >Leaderboard
          </a>
        </li>
        <li class="mb-10 ms-4">
          <time class="mb-1 text-sm font-normal leading-none text-gray-500"
            >August 2024</time
          >
          <h3 class="text-lg font-semibold text-white">Scorehammer 40,000</h3>
          <p class="mb-4 text-base font-normal text-gray-400">
            Purge Xenos filth by completing games as tributes for the Warhammer
            factions!
          </p>
          <a
            routerLink="/aug-bonus"
            class="inline-flex items-center rounded-lg border border-gray-600 bg-gray-800 px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-700"
            >Read the Details
            <svg
              class="ml-2 ms-2 h-3 w-3 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              /></svg
          ></a>
          <a
            routerLink="/aug-leaderboard"
            class="ml-2 inline-flex items-center rounded-lg border border-green-600 bg-green-800 px-4 py-2 text-sm font-medium text-green-400 hover:bg-green-700 hover:text-white focus:z-10 focus:text-green-700 focus:outline-none focus:ring-4 focus:ring-gray-700 disabled:cursor-default disabled:bg-gray-500"
            disabled
            >Leaderboard
          </a>
        </li>
        <li class="mb-10 ms-4">
          <time class="mb-1 text-sm font-normal leading-none text-gray-500"
            >July 2024</time
          >
          <h3 class="text-lg font-semibold text-white">Tamriel Tea Party</h3>
          <p class="mb-4 text-base font-normal text-gray-400">
            Celebrate this historical event by dumping (completing) games with
            'T'!
          </p>
          <a
            routerLink="/jul-bonus"
            class="inline-flex items-center rounded-lg border border-gray-600 bg-gray-800 px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-700"
            >Read the Details
            <svg
              class="ml-2 ms-2 h-3 w-3 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              /></svg
          ></a>
          <a
            routerLink="/jul-leaderboard"
            class="ml-2 inline-flex items-center rounded-lg border border-green-600 bg-green-800 px-4 py-2 text-sm font-medium text-green-400 hover:bg-green-700 hover:text-white focus:z-10 focus:text-green-700 focus:outline-none focus:ring-4 focus:ring-gray-700 disabled:cursor-default disabled:bg-gray-500"
            disabled
            >Leaderboard
          </a>
        </li>
        <li class="mb-10 ms-4">
          <time class="mb-1 text-sm font-normal leading-none text-gray-500"
            >June 2024</time
          >
          <h3 class="text-lg font-semibold text-white">Critical Review</h3>
          <p class="mb-4 text-base font-normal text-gray-400">
            Play good games... as dictated by your peers?! The better recieved,
            the more points!
          </p>
          <a
            routerLink="/jun-bonus"
            class="inline-flex items-center rounded-lg border border-gray-600 bg-gray-800 px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-700"
            >Read the Details
            <svg
              class="ml-2 ms-2 h-3 w-3 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              /></svg
          ></a>
          <a
            routerLink="/jun-leaderboard"
            class="ml-2 inline-flex items-center rounded-lg border border-green-600 bg-green-800 px-4 py-2 text-sm font-medium text-green-400 hover:bg-green-700 hover:text-white focus:z-10 focus:text-green-700 focus:outline-none focus:ring-4 focus:ring-gray-700 disabled:cursor-default disabled:bg-gray-500"
            disabled
            >Leaderboard
          </a>
        </li>
        <li class="mb-10 ms-4">
          <time class="mb-1 text-sm font-normal leading-none text-gray-500"
            >May 2024</time
          >
          <h3 class="text-lg font-semibold text-white">Genre Tower</h3>
          <p class="mb-4 text-base font-normal text-gray-400">
            Build towers with your favorite genres and ascending ratio, how tall
            can you build?
          </p>
          <a
            routerLink="/may-bonus"
            class="inline-flex items-center rounded-lg border border-gray-600 bg-gray-800 px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-700"
            >Read the Details
            <svg
              class="ml-2 ms-2 h-3 w-3 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              /></svg
          ></a>
          <!-- <a
            routerLink="/mar-leaderboard"
            class="ml-2 inline-flex items-center rounded-lg border border-green-600 bg-green-800 px-4 py-2 text-sm font-medium text-green-400 hover:bg-green-700 hover:text-white focus:z-10 focus:text-green-700 focus:outline-none focus:ring-4 focus:ring-gray-700 disabled:cursor-default disabled:bg-gray-500"
            disabled
            >Leaderboard
          </a> -->
        </li>
        <li class="mb-10 ms-4">
          <time class="mb-1 text-sm font-normal leading-none text-gray-500"
            >April 2024</time
          >
          <h3 class="text-lg font-semibold text-white">Broked Games Matter</h3>
          <p class="mb-4 text-base font-normal text-gray-400">
            Dive back into those games you left behind for your first (and
            possibly last) chance to cash in points for them!
          </p>
          <a
            routerLink="/apr-bonus"
            class="inline-flex items-center rounded-lg border border-gray-600 bg-gray-800 px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-700"
            >Read the Details
            <svg
              class="ml-2 ms-2 h-3 w-3 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              /></svg
          ></a>
          <a
            routerLink="/apr-leaderboard"
            class="ml-2 inline-flex items-center rounded-lg border border-green-600 bg-green-800 px-4 py-2 text-sm font-medium text-green-400 hover:bg-green-700 hover:text-white focus:z-10 focus:text-green-700 focus:outline-none focus:ring-4 focus:ring-gray-700 disabled:cursor-default disabled:bg-gray-500"
            disabled
            >Leaderboard
          </a>
        </li>
        <li class="mb-10 ms-4">
          <time class="mb-1 text-sm font-normal leading-none text-gray-500"
            >March 2024</time
          >
          <h3 class="text-lg font-semibold text-white">Bounty Board</h3>
          <p class="mb-4 text-base font-normal text-gray-400">
            Take home the biggest bounty by being one of the few (or only!) one
            to complete a game across a list of specified games!
          </p>
          <a
            routerLink="/mar-bonus"
            class="inline-flex items-center rounded-lg border border-gray-600 bg-gray-800 px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-700"
            >Read the Details
            <svg
              class="ml-2 ms-2 h-3 w-3 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              /></svg
          ></a>
          <a
            routerLink="/mar-leaderboard"
            class="ml-2 inline-flex items-center rounded-lg border border-green-600 bg-green-800 px-4 py-2 text-sm font-medium text-green-400 hover:bg-green-700 hover:text-white focus:z-10 focus:text-green-700 focus:outline-none focus:ring-4 focus:ring-gray-700"
            >Leaderboard
          </a>
        </li>
        <li class="mb-10 ms-4">
          <time class="mb-1 text-sm font-normal leading-none text-gray-500"
            >February 2024</time
          >
          <h3 class="text-lg font-semibold text-white">Spread the Love</h3>
          <p class="mb-4 text-base font-normal text-gray-400">
            Complete the same games as your fellow contestants, the more
            completions of the same game the more bonus points!
            <i>Just what kind of lover are you?</i>
          </p>
          <a
            routerLink="/feb-bonus"
            class="inline-flex items-center rounded-lg border border-gray-600 bg-gray-800 px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-700"
            >Read the Details
            <svg
              class="ml-2 ms-2 h-3 w-3 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              /></svg
          ></a>
          <a
            routerLink="/feb-leaderboard"
            class="ml-2 inline-flex items-center rounded-lg border border-green-600 bg-green-800 px-4 py-2 text-sm font-medium text-green-400 hover:bg-green-700 hover:text-white focus:z-10 focus:text-green-700 focus:outline-none focus:ring-4 focus:ring-gray-700"
            >Leaderboard
          </a>
        </li>
        <li class="mb-10 ms-4">
          <time class="mb-1 text-sm font-normal leading-none text-gray-500"
            >January 2024</time
          >
          <h3 class="text-lg font-semibold text-white">
            Blast Through The Past
          </h3>
          <p class="mb-4 text-base font-normal text-gray-400">
            Complete games across the years, the older the release date, the
            more the points! Don't forget to complete at least one Xbox 360 game
            to qualify for the Community Bonus!
          </p>
          <a
            routerLink="/jan-bonus"
            class="inline-flex items-center rounded-lg border border-gray-600 bg-gray-800 px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-700"
            >Read the Details
            <svg
              class="ml-2 ms-2 h-3 w-3 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              /></svg
          ></a>
          <a
            routerLink="/jan-leaderboard"
            class="ml-2 inline-flex items-center rounded-lg border border-green-600 bg-green-800 px-4 py-2 text-sm font-medium text-green-400 hover:bg-green-700 hover:text-white focus:z-10 focus:text-green-700 focus:outline-none focus:ring-4 focus:ring-gray-700"
            >Leaderboard
          </a>
        </li>
      </ol>
    </section>
  </div>
</section>
