<ng-container *ngIf="this.isPlayerLoading || this.isRgscLoading">
  <main
    class="absolute left-0 top-[65px] flex h-[calc(100%-65px)] w-full items-center justify-center bg-black bg-opacity-50"
  >
    <img src="../../../assets/infinite-spinner.svg" class="h-16" />
  </main>
</ng-container>
<div *ngIf="!this.isPlayerLoading && !this.isRgscLoading">
  <section class="flex flex-col items-center justify-center align-middle">
    <div
      class="m-4 max-w-md rounded-lg border border-gray-700 bg-gray-900 px-6 shadow"
    >
      <div class="relative flex items-center gap-8 p-4">
        <img
          class="h-20 w-20 rounded-full shadow-lg"
          src="{{ bcmPlayerSummary.user.avatar }}"
          (error)="handleImageLoad($event)"
        />
        <div class="self-start text-sm text-gray-400">
          <ul>
            <li>
              <h5 class="text-lg font-medium text-white">
                {{ bcmPlayerSummary.user.gamertag }}'s RGSC
              </h5>
            </li>
            <li>
              <span class="text-gray-600">Completed:&nbsp;</span
              ><span class="text-green-500">{{
                rgscSummary?.rgscsCompleted?.length ?? 0
              }}</span>
              / 11
            </li>
            <li>
              <span class="text-gray-600">Rerolls Left:&nbsp;</span
              >{{ rgscSummary?.rerollsRemaining ?? "--" }}
            </li>
            <li>
              <span class="text-gray-600">Pool Size:&nbsp;</span>
              <span
                [ngClass]="{
                  'text-red-600':
                    rgscSummary.currentRandoms?.poolSize < 50 &&
                    rgscSummary.currentRandoms[0]?.rgsc?.poolSize < 50
                }"
                >{{
                  rgscSummary.currentRandoms[0]?.rgsc?.poolSize ??
                    rgscSummary.currentRandoms?.poolSize ??
                    0
                }}</span
              >
            </li>
          </ul>
          <button
            [routerLink]="['/player/', playerName]"
            class="my-2 me-2 rounded-lg border border-green-700 px-3 py-1.5 text-center text-sm font-medium text-green-700 hover:bg-green-800 hover:text-white focus:outline-none focus:ring-4 focus:ring-green-300 dark:border-green-500 dark:text-green-500 dark:hover:bg-green-600 dark:hover:text-white dark:focus:ring-green-800"
          >
            Return to Profile
          </button>
        </div>
      </div>
    </div>
  </section>

  <ul
    class="grid grid-cols-1 gap-4 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3"
  >
    <li *ngFor="let random of rgscSummary.currentRandoms">
      <div
        [ngClass]="{
          'flex flex-col rounded-lg border border-gray-700 bg-gray-800 shadow hover:bg-gray-700': true,
          'border-2 border-green-600': isGameCompleted(random.game?.id ?? 0)
        }"
      >
        <div class="relative flex flex-col justify-between p-4 leading-normal">
          <span
            *ngIf="isGameCompleted(random.game?.id ?? 0)"
            class="absolute left-0 top-0 flex gap-1 rounded-tl bg-green-600 px-2 py-1 text-xs font-thin"
            ><img
              src="../../../../assets/checkmark-seal-svgrepo-com.svg"
              class="mr-0.5 h-4 w-4"
            />{{
              getGameCompletion(random.game?.id ?? 0) | date : "MMM d"
            }}</span
          >
          <span
            class="absolute right-0 top-0 rounded-tr bg-green-600 px-2 py-1 text-xs font-bold"
            >{{ determineChallengeMonth(random.rgsc.challenge) }}</span
          >
          <h5
            class="mb-2 text-center text-xl font-bold tracking-tight text-white"
          >
            {{ random.game?.title ?? "None Drawn" }}
          </h5>
          <span class="-mt-3 block text-center text-xs opacity-50">
            {{ random.game?.publisher ?? "Update" }} -
            {{ random.game?.developer ?? "Your Collection" }}</span
          >
          <div class="mt-4 font-normal text-gray-400">
            <div class="flex place-content-between">
              <span
                >~{{ random.game?.fullCompletionEstimate ?? "??" }} hours</span
              >
              <span
                >{{
                  random.game?.siteRatio ?? 0 | number : "1.2-2"
                }}
                ratio</span
              >
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngFor="let rolledRandom of rgscSummary.randomsRolledAway">
        <ng-container
          *ngIf="random.rgsc.challenge === rolledRandom.rgsc.challenge"
        >
          <div
            class="flex items-center justify-between gap-x-6 rounded-b-lg border border-t-0 border-gray-700 bg-gray-900 px-2 py-1 text-xs font-thin text-gray-400 opacity-50 shadow hover:bg-gray-800"
          >
            <h5 class="flex-1 tracking-tight">
              {{ rolledRandom.game.title }}
            </h5>
            <span
              >{{ rolledRandom.game.siteRatio | number : "1.2-2" }} Ratio &nbsp;
              ~{{ rolledRandom.game.fullCompletionEstimate }} Hours</span
            >
            <span class="flex gap-1 text-white">
              <img
                src="../../../../assets/dice-gambling-game-svgrepo-com.svg"
                class="h-5 w-5"
              />Rerolled
              {{ rolledRandom.rgsc.rerollDate | date : "MMM d" }}</span
            >
          </div>
        </ng-container>
      </ng-container>
    </li>
  </ul>
</div>
