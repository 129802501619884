<section class="flex flex-col items-center gap-y-6 text-gray-300">
  <img
    src="https://imgur.com/6wY13s6.png"
    class="max-w-sm self-center sm:max-w-md"
  />
  <article class="flex max-w-2xl flex-col gap-y-6">
    <h1 class="text-2xl font-semibold">So long and thanks for all the fish!</h1>
    <p>
      We wanted to do something a little different, and hopefully a little
      special, for our final BCMX event. We're going to kick things off by
      recognizing the podium from each iteration of BCM over the years. (<i
        >I wonder why...</i
      >)
    </p>
    <h3 class="mb-0 text-xl font-semibold">2019</h3>
    <ul class="list-disc ml-5">
      <li>
        <a
          href="https://www.trueachievements.com/gamer/SlayingUrchin3"
          class="text-green-500 hover:text-green-800"
          >SlayingUrchin3</a
        >
      </li>
      <li>
        <a
          href="https://www.trueachievements.com/gamer/Gensui+Akainu"
          class="text-green-500 hover:text-green-800"
          >Gensui Akainu</a
        >
      </li>
      <li>
        <a
          href="https://www.trueachievements.com/gamer/SabenRothschild"
          class="text-green-500 hover:text-green-800"
          >SabenRothschild</a
        >
      </li>
    </ul>
    <h3 class="mb-0 text-xl font-semibold">2020</h3>
    <ul class="list-disc ml-5">
      <li>
        <a
          href="https://www.trueachievements.com/gamer/DANIELJJ14"
          class="text-green-500 hover:text-green-800"
          >DANIELJJ14</a
        >
      </li>
      <li>
        <a
          href="https://www.trueachievements.com/gamer/JimbotUK"
          class="text-green-500 hover:text-green-800"
          >JimbotUK</a
        >
      </li>
      <li>
        <a
          href="https://www.trueachievements.com/gamer/Christoph+5782"
          class="text-green-500 hover:text-green-800"
          >Christoph 5782</a
        >
      </li>
    </ul>
    <h3 class="mb-0 text-xl font-semibold">2021</h3>
    <ul class="list-disc ml-5">
      <li>
        <a
          href="https://www.trueachievements.com/gamer/Fine+Feat"
          class="text-green-500 hover:text-green-800"
          >Fine Feat</a
        >
      </li>
      <li>
        <a
          href="https://www.trueachievements.com/gamer/DubstepEdgelord"
          class="text-green-500 hover:text-green-800"
          >DubstepEdgelord</a
        >
      </li>
      <li>
        <a
          href="https://www.trueachievements.com/gamer/Infamous"
          class="text-green-500 hover:text-green-800"
          >Infamous</a
        >
      </li>
    </ul>
    <h3 class="mb-0 text-xl font-semibold">2022</h3>
    <ul class="list-disc ml-5">
      <li>
        <a
          href="https://www.trueachievements.com/gamer/Luke17000"
          class="text-green-500 hover:text-green-800"
          >Luke17000</a
        >
      </li>
      <li>
        <a
          href="https://www.trueachievements.com/gamer/smrnov"
          class="text-green-500 hover:text-green-800"
          >smrnov</a
        >
      </li>
      <li>
        <a
          href="https://www.trueachievements.com/gamer/Fine+Feat"
          class="text-green-500 hover:text-green-800"
          >Fine Feat</a
        >
      </li>
    </ul>
    <h3 class="mb-0 text-xl font-semibold">2023</h3>
    <ul class="list-disc ml-5">
      <li>
        <a
          href="https://www.trueachievements.com/gamer/SlayingUrchin3"
          class="text-green-500 hover:text-green-800"
          >SlayingUrchin3</a
        >
      </li>
      <li>
        <a
          href="https://www.trueachievements.com/gamer/Fine+Feat"
          class="text-green-500 hover:text-green-800"
          >Fine Feat</a
        >
      </li>
      <li>
        <a
          href="https://www.trueachievements.com/gamer/SwiftSupafly"
          class="text-green-500 hover:text-green-800"
          >SwiftSupafly</a
        >
      </li>
    </ul>
    <p>
      "So Long And Thanks For All The Fish" is going to be all about following
      in their footsteps! With the event ending we may never have the chance to
      earn a podium spot ourselves, but we can at least try to play like those
      who did!
    </p>
    <p>
      This time around you will all be tasked with completing games that the
      above gamers completed during the years that they earned a podium
      placement. For example, should you choose to follow in
      <a
        href="https://www.trueachievements.com/gamer/SwiftSupafly"
        class="text-green-500 hover:text-green-800"
        >SwiftSupafly</a
      >'s footsteps (not that I'd recommend it!) you'd have to complete a game
      that they completed during 2023.
    </p>
    <p>
      For any of the glory hogs who have appeared on the podium more than once -
      yes, all of those years are valid!
    </p>
    <p>The scoring will work as follows:</p>
    <ul>
      <li>1st place contestant = 60% of BCMX value + (XX% of BCMX Value)</li>
      <li>2nd place contestant = 40% of BCMX value + (XX% of BCMX Value)</li>
      <li>3rd place contestant = 20% of BCMX value + (XX% of BCMX Value)</li>
    </ul>
    <p>
      To gain a higher XX% - we're asking you to explore the podium offerings
      from each year. For each yearly podium (group of 3) that you manage to
      complete a game from an additional 5% will be granted (to a maximum of
      25%). This additional total will apply to all games that work for the
      bonus.
    </p>
    <p>
      To gain participation credit for the month - you simply have to earn bonus
      points by completing any qualifying game. Please don't forget the standard
      game restrictions listed at the end of the blog. A good portion of each
      gamer's completions that year are likely not BCMX eligible!
    </p>
    <div class="bg-slate-900 p-5">
      We will be using each gamer's completion history per their TA profile. The
      completion dates listed in their Game Collection in particular. We will
      not be referencing old BCM spreadsheets.
    </div>
    <h2 class="font-2xl font-semibold tracking-wider">
      But wait, there’s more!
    </h2>
    <p>
      Remember, this is going to be the last chance for a lot of us to earn a
      podium (even pretend podiums like this)! As such, we'd like to see a whole
      slew of them trickle in!
    </p>
    <p>We'll be looking for a combined total of 400 podiums submitted!</p>
    <p>
      To qualify for the community bonus you will need to complete a game from
      any 1st place gamer, 2nd place game and third place gamer for a full
      podium sweep!
    </p>
    <p>This is an all-or-nothing bonus pool.</p>
    <p>Important Rules:</p>
    <ul class="list-disc ml-4 mb-6">
      <li>
        For any completion to count towards this bonus the ratio must be at or
        above 1.5 and have a time estimate at or above 5-6 hours. <b>Or</b> the
        time estimate must be at or above 20 hours regardless of ratio.
      </li>
      <li>
        Stacks will count as separate games due to technical restrictions.
      </li>
      <li>
        Any game released in November of 2024 will not qualify for this bonus.
        Additionally, any completion that is due to a new DLC or TU releasing in
        November of 2024 also will not qualify for this bonus.
      </li>
    </ul>
  </article>
</section>
