import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aug-bonus',
  templateUrl: './aug-bonus.component.html',
})
export class AugBonusComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
