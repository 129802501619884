<div
  class="relative max-h-[calc(100vh-120px)] overflow-x-auto shadow-md sm:rounded-lg"
>
  <table
    class="w-full min-w-[1200px] text-left text-sm text-gray-400 rtl:text-right"
  >
    <caption class="bg-gray-800 p-5 text-left text-lg font-semibold text-white">
      Better Completions Matter 2024
      <br />
      <span class="text-sm font-thin text-gray-500"
        >(Last Updated: {{ lastScan | date : "short" : "UTC" }})</span
      >
      <p class="mt-1 text-sm font-normal text-gray-400">
        This leaderboard updates automatically at the beginning of each month,
        defaulting users in descending order of Total Points
      </p>
    </caption>
    <thead
      class="sticky top-0 bg-gray-700 text-[.6rem] uppercase text-gray-400"
    >
      <tr>
        <th
          scope="col"
          (click)="sortColumn('#')"
          class="cursor-pointer p-2 hover:text-green-500 sm:p-4"
        >
          #
        </th>
        <th
          scope="col"
          class="cursor-pointer px-4 py-2 hover:text-green-500 sm:px-6 sm:py-3"
          (click)="sortColumn('player')"
        >
          Player
        </th>
        <th
          scope="col"
          class="cursor-pointer px-4 py-2 text-center text-yellow-400 hover:text-green-500 sm:px-6 sm:py-3"
          (click)="sortColumn('totalPoints')"
        >
          Total Points
        </th>
        <th
          scope="col"
          class="cursor-pointer px-4 py-2 text-center text-violet-400 hover:text-green-500 sm:px-6 sm:py-3"
          (click)="sortColumn('bonusPoints')"
        >
          Bonus Points
        </th>
        <th
          scope="col"
          class="cursor-pointer px-4 py-2 text-center hover:text-green-500 sm:px-6 sm:py-3"
          (click)="sortColumn('basePoints')"
        >
          Base Points
        </th>
        <th
          scope="col"
          class="cursor-pointer px-4 py-2 text-center hover:text-green-500 sm:px-6 sm:py-3"
          (click)="sortColumn('avgBcmPoints')"
        >
          Avg Points
        </th>
        <th
          scope="col"
          class="cursor-pointer px-4 py-2 text-center hover:text-green-500 sm:px-6 sm:py-3"
          (click)="sortColumn('completes')"
        >
          Completed
        </th>
        <th
          scope="col"
          class="cursor-pointer px-4 py-2 text-center hover:text-green-500 sm:px-6 sm:py-3"
          (click)="sortColumn('avgRatio')"
        >
          Avg Ratio
        </th>
        <th
          scope="col"
          class="cursor-pointer px-4 py-2 text-center hover:text-green-500 sm:px-6 sm:py-3"
          (click)="sortColumn('avgTime')"
        >
          Avg Time
        </th>
        <th
          scope="col"
          class="cursor-pointer px-4 py-2 text-center hover:text-green-500 sm:px-6 sm:py-3"
          (click)="sortColumn('highestRatio')"
        >
          Highest Ratio
        </th>
        <th
          scope="col"
          class="cursor-pointer px-4 py-2 text-center hover:text-green-500 sm:px-6 sm:py-3"
          (click)="sortColumn('highestTime')"
        >
          Highest Time
        </th>
      </tr>
    </thead>
    <tbody *ngIf="isLoading">
      <tr>
        <td colspan="11" class="h-60 w-full bg-black bg-opacity-50">
          <img src="../../../assets/infinite-spinner.svg" class="m-auto h-16" />
        </td>
      </tr>
    </tbody>
    <tbody class="max-h-dvh overflow-y-auto" *ngIf="!isLoading">
      <tr
        *ngFor="let player of leaderboard"
        [routerLink]="['/player/', player.user.gamertag]"
        class="group border-b border-gray-700 bg-gray-800 hover:cursor-pointer"
      >
        <td class="w-1 p-1 text-center group-hover:bg-gray-600 sm:w-2 sm:p-2">
          <span
            *ngIf="player.bcmStats?.rank === 1"
            class="text-2xl font-bold text-yellow-400"
            >{{ player.bcmStats.rank | ordinal }}
          </span>
          <span
            *ngIf="player.bcmStats?.rank === 2"
            class="text-xl font-bold text-slate-500"
            >{{ player.bcmStats.rank | ordinal }}</span
          >
          <span
            *ngIf="player.bcmStats?.rank === 3"
            class="text-xl font-bold text-amber-800"
            >{{ player.bcmStats.rank | ordinal }}</span
          >
          <span
            class="text-lg"
            *ngIf="
              player.bcmStats?.rank !== 1 &&
              player.bcmStats?.rank !== 2 &&
              player.bcmStats?.rank !== 3 &&
              player.bcmStats
            "
            >{{ player.bcmStats?.rank | ordinal }}</span
          >
          <span *ngIf="!player.bcmStats">--</span>
          <span
            [ngClass]="{
              'text-center text-xs font-light': true,
              'text-green-400': player.bcmStats?.rankMovement > 0,
              'text-red-400': player.bcmStats?.rankMovement < 0
            }"
            *ngIf="player.bcmStats?.rankMovement"
            ><br />({{ player.bcmStats?.rankMovement > 0 ? "+" : ""
            }}{{ player.bcmStats?.rankMovement }})</span
          >
        </td>
        <th
          scope="row"
          class="flex items-center whitespace-nowrap px-1 py-4 text-white group-hover:bg-gray-600"
        >
          <ng-container *ngIf="player.user.avatar"
            ><img
              class="h-6 w-6 rounded-full sm:h-8 sm:w-8"
              src="{{ player.user.avatar }}"
              (error)="handleImageLoad($event)"
          /></ng-container>
          <ng-container *ngIf="!player.user.avatar">
            <svg
              class="-left-1 h-6 w-6 text-gray-400 sm:h-8 sm:w-8"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </ng-container>
          <div class="pl-2 sm:pl-3">
            <div class="font-normal sm:font-semibold">
              {{ player.user.gamertag }}
            </div>
            <div class="text-xs font-normal text-gray-500">
              {{ player.user.region || "--" }}
              <span *ngIf="!!player.user.area">-</span>
              {{ player.user.area }}
            </div>
          </div>
        </th>
        <td
          class="px-2 py-2 text-center font-bold text-yellow-400 group-hover:bg-gray-600"
        >
          {{ player.bcmStats?.totalPoints | number : "0.0-0" }}
        </td>
        <td
          class="px-2 py-2 text-center text-violet-400 group-hover:bg-gray-600"
        >
          {{ player.bcmStats?.bonusPoints | number : "0.0-0" }}
        </td>
        <td class="px-2 py-2 text-center group-hover:bg-gray-600">
          {{ player.bcmStats?.basePoints | number : "0.0-0" }}
        </td>
        <td class="px-2 py-2 text-center group-hover:bg-gray-600">
          {{ player.bcmStats?.averagePoints | number : "1.0-1" }}
        </td>
        <td class="px-2 py-2 text-center group-hover:bg-gray-600">
          {{ player.bcmStats?.completions }}
        </td>
        <td class="px-2 py-2 text-center group-hover:bg-gray-600">
          {{ player.bcmStats?.averageRatio | number : "1.3-3" }}
        </td>
        <td class="px-2 py-2 text-center group-hover:bg-gray-600">
          {{ player.bcmStats?.averageTimeEstimate | number : "1.0-1" }}
        </td>
        <td class="px-2 py-2 text-center group-hover:bg-gray-600">
          {{ player.bcmStats?.highestRatio | number : "1.3-3" }}
        </td>
        <td class="px-2 py-2 text-center group-hover:bg-gray-600">
          {{ player.bcmStats?.highestTimeEstimate | number : "1.0-1" }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
